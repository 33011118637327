<template>
  <a-card title="">
    <div>
        <ve-histogram 
        :data="item" 
        :title="{
            text:`学员上课统计 ${item.title?`(${item.title})`:''}`,
            subtext: start_date==end_date?`${start_date}`:`${start_date} ~ ${end_date}`,
            textStyle: {
                fontSize: 15,
            },
            subtextStyle: {
                fontSize: 12.5
            }
        }"
        :legend="{show:false}"
        judge-width
        :grid="{right: '5%'}"
        :mark-line="markLine"
        :settings="{label: { show: true, position: 'top'},yAxisType: ['KMB', 'KMB']}" 
        :extend="{
            yAxis:{
                splitLine: {
                lineStyle: {
                    type: 'dotted'
                }
                }
            },
            series:{
                type:'bar',
                barMaxWidth: 10,
                itemStyle:{
                    normal:{
                        color: function(params) {
                            return {
                                type: 'linear',
                                x:1,
                                y:1,
                                x2:0,
                                y2:0,
                                colorStops: [{
                                    offset: 0,
                                    color: '#effffb'
                                    
                                }, {
                                    offset: 1,                    
                                    color: '#23d9ae'
                                }],
                            }
                        },
                        barBorderRadius:[30,30,0,0]
                    }
                }
        },
        dataZoom:[{type:'inside'}]}" >
        </ve-histogram>
        <LEmpty v-if="first>0 && item.rows.length == 0"/>
    </div>
  </a-card>
</template>

<script>
export default {
  name: 'room',
  props:['item' , 'start_date', 'end_date','loading'],

  data () {
    this.markLine = {
      symbol: ['none', 'none'], //去掉箭头
      data: [
        {
          name: '平均线',
          type: 'average'
        }
      ]
    }
    return {
      first:0
    }
  },
  watch: {
        loading(newValue, oldValue) {
            this.first++;
        }
    },
}
</script>

<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>教务数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>教室统计</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>上课校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="dashboard bk-group">
                <statistics :count='counts'/>
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <roomOne :loading='loading'  :item ='room' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <!-- <roomSummate :loading='loadingS'  :item ='roomS' :start_date='paramsData.start_date' :end_date='paramsData.end_date' /> -->
                        <LRanking title="教室排课数" :rank_data="roomS" :loading="loadingS" :start_date='paramsData.start_date' :end_date='paramsData.end_date' /> 
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <!-- <roomBook :loading='loadingB'  :item ='roomB' :start_date='paramsData.start_date' :end_date='paramsData.end_date' /> -->
                        <LRanking title="学员上课统计" :rank_data="roomB" :loading="loadingB" :start_date='paramsData.start_date' :end_date='paramsData.end_date' /> 
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import roomOne from './room'
import roomSummate from './roomSummate'
import roomBook from './roomBook'
import statistics from '@/views/bi/ranking/money/statistics'
export default {
  name: 'jssjtj',

  data() {
    return {
      loading:false,
      loadingS:false,
      loadingB:false,
      visible: false,
      room:{},
      roomS:{},
      roomB:{},
      counts:[],
      studios:[],
      paramsData:{
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        studio_id:'',
        filter_type:'week'
      },
    }
  },
  mixins: [ranges],
  components:{
    roomOne,
    roomBook,
    roomSummate,
    statistics
  },
  created() {
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getStudio()
  },

  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList(obj){
      this.getCount(obj)
      this.getRoom(obj)
      this.getRoomSummate(obj)
      this.getRoomBook(obj)
    },
    async getRoom(obj){
      this.loading = true
      let res = await this.$store.dispatch('analysisRoomAction', obj)
      this.room = res.data
      this.loading = false
    },
    async getRoomSummate(obj){
      this.loadingS = true
      let resS = await this.$store.dispatch('analysisRoomSummateAction', obj)
      this.roomS = resS.data
      this.loadingS = false
    },
    async getRoomBook(obj){
      this.loadingB = true
      let resB = await this.$store.dispatch('analysisRoomBookAction', obj)
      this.roomB = resB.data
      this.loadingB = false
    },

    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisRoomCountAction', obj)
      this.counts = res.data
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen();
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    }
  }
}
</script>